<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat>
          <v-toolbar-title>Surveys</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="ma-3" color="accent" small to="new-survey">
            <v-icon left dark>mdi-plus</v-icon>new survey
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="search" label="Search"></v-text-field>
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="surveys"
                :search="search"
              >
                <template v-slot:item.name="{ item }">
                  <v-btn text :to="`/survey/details/${item.id}`" color="accent">{{ item.name }}</v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("survey", ["surveys"])
  },
  data() {
    return {
      loading: false,
      search: null,
      // surveys: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Rules", value: "rules.length" },
        { text: "Schedules", value: "schedules.length" },
        { text: "Status", value: "status" },
        { text: "Description", value: "description" }
      ]
    };
  },
  methods: {},
  created() {}
};
</script>